import { css } from "linaria"

import { colors } from "src/styles/members"

import "@fortawesome/fontawesome-free/css/fontawesome.min.css"
import "@fortawesome/fontawesome-free/css/solid.min.css"
import "@fortawesome/fontawesome-free/css/regular.min.css"

const global = css`
  :global() {
    @import url(https://fonts.googleapis.com/css?family=Kurale:400);

    html,
    body {
      scroll-behavior: smooth;
      font-family: "Kurale", "Helvetica", "Arial", serif;
      line-height: 1.5;
      background-color: ${colors.white};
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    h1,
    h2,
    h2,
    h4,
    h5,
    h6 {
      line-height: 1.2;
    }

    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
    }
  }
`

export default global
