import React from "react"
import { css } from "linaria"
import cx from "clsx"

import Section from "src/components/section"
import Container from "src/components/container"
import { device } from "src/utils/capacitor"

import GooglePlayStoreBadge from "src/images/play-store-badge.png"
import AppleAppStoreBadge from "src/images/app-store-badge.png"
import { media, colors, spacing } from "src/styles/members"

const footer = css`
  background-color: ${colors.black};
  color: ${colors.white};
  font-size: ${spacing[5]};
  ${media.md.up} {
    font-size: ${spacing[6]};
  }
  text-align: center;
  .store-links {
    margin-bottom: ${spacing[4]};
    a {
      margin-left: ${spacing[2]};
      margin-right: ${spacing[2]};
      display: inline-block;
      img {
        display: block;
        height: ${spacing[9]};
        ${media.md.up} {
          height: ${spacing[10]};
        }
      }
    }
  }
  .footer-item {
    &:after {
      content: "|";
      margin: 0 ${spacing[3]};
    }
    &:last-child:after {
      content: none;
    }
  }
`

const Footer = props => {
  const {
    siteTitle,
    branchTitle,
    googlePlayStoreUrl,
    appleAppStoreUrl,
    className,
    narrow,
  } = props
  const isBrowser = !device.isNative()
  return (
    <Section className={cx(className, footer)} jigTop narrow={narrow}>
      <Container>
        <div className="store-links">
          {isBrowser && googlePlayStoreUrl && (
            <a
              href={googlePlayStoreUrl}
              title="Google Play Store"
              target="_blank"
              rel="noreferrer"
              role="button"
              aria-label="Google Play Store"
            >
              <img src={GooglePlayStoreBadge} alt="Google Play Store" />
            </a>
          )}
          {isBrowser && appleAppStoreUrl && (
            <a
              href={appleAppStoreUrl}
              title="Apple App Store"
              target="_blank"
              rel="noreferrer"
              role="button"
              aria-label="Apple App Store"
            >
              <img src={AppleAppStoreBadge} alt="Apple App Store" />
            </a>
          )}
        </div>
        <div>
          <span className="footer-item">
            © {new Date().getFullYear()}{" "}
            {[siteTitle, branchTitle].filter(Boolean).join(" - ")}
          </span>
          {!device.isNative() && (
            <a href="/docs/consent.pdf" target="_blank" className="footer-item">
              Použitie cookies
            </a>
          )}
        </div>
      </Container>
    </Section>
  )
}

export default Footer
