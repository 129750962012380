import React from "react"
import { css } from "linaria"
import cx from "clsx"

import { media } from "src/styles/members"

const section = css`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    background-size: 25px 100%;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    background-size: 25px 100%;
  }
  &.jigTop {
    padding-top: 25px; /* fix jig spacing */
    &:before {
      background-image: linear-gradient(583deg, white 50%, transparent 0),
        linear-gradient(136deg, white 50%, transparent 0);
    }
  }
  &.jigBottom {
    padding-bottom: 25px; /* fix jig spacing */
    &:after {
      background-image: linear-gradient(315deg, white 50%, transparent 0),
        linear-gradient(45deg, white 50%, transparent 0);
    }
  }
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @supports (-webkit-touch-callout: inherit) {
    background-attachment: scroll;
  }
`

const sectionContentStyle = css`
  margin-top: -20px; /* fix scroll to anchor mobile */
  padding-top: calc(40px + 20px);
  padding-bottom: 40px;
  ${media.md.up} {
    margin-top: -60px; /* fix scroll to anchor desktop */
    padding-top: calc(80px + 60px);
    padding-bottom: 80px;
  }
  &.narrow {
    margin-top: -20px; /* fix scroll to anchor mobile */
    padding-top: calc(0px + 20px);
    padding-bottom: 30px;
    ${media.md.up} {
      margin-top: -60px; /* fix scroll to anchor desktop */
      padding-top: calc(0px + 60px);
      padding-bottom: 30px;
    }
  }
`

const Section = props => {
  const { id, className, children, paraImg, jigTop, jigBottom, narrow } = props
  const paralaxStyle = paraImg && {
    backgroundImage: `url(${paraImg})`,
  }
  return (
    <section
      className={cx(section, className, { jigTop }, { jigBottom })}
      style={paralaxStyle}
    >
      <div id={id} className={cx(sectionContentStyle, { narrow })}>
        {children}
      </div>
    </section>
  )
}

export default Section
