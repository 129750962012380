import React from "react"
import { css } from "linaria"
import cx from "clsx"

import { media, colors, squish, spacing } from "src/styles/members"

const subtitle = css`
  display: block;
  ${media.md.up} {
    display: inline-block;
  }
  color: ${colors.primary};
  background-color: ${colors.white};
  margin-bottom: ${spacing[5]};
  ${squish(spacing[2])}
  font-size: ${spacing[6]};
  ${media.md.up} {
    margin-bottom: ${spacing[7]};
    font-size: ${spacing[8]};
  }
`

const SubTitle = props => {
  const { text, className } = props
  return (
    <div>
      <h2 className={cx(subtitle, className)}>{text}</h2>
    </div>
  )
}

export default SubTitle
