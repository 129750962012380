import React from "react"
import { css } from "linaria"
import cx from "clsx"

import { colors, media, squish, spacing } from "src/styles/members.js"

const title = css`
  display: block;
  ${media.md.up} {
    display: inline-block;
  }
  color: ${colors.white};
  background-color: ${colors.primary};
  text-transform: uppercase;
  margin-bottom: ${spacing[5]};
  ${squish(spacing[2])}
  font-size: ${spacing[9]};
  ${media.md.up} {
    margin-bottom: ${spacing[7]};
    font-size: ${spacing[11]};
  }
`

const Title = props => {
  const { text, className } = props
  return (
    <div>
      <h1 className={cx(title, className)}>{text}</h1>
    </div>
  )
}

export default Title
