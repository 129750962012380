import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ description = "", lang = "sk", meta = [], title, subtitle }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={[title, subtitle].filter(Boolean).join(" | ")}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "bwtplus",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
