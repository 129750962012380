import React from "react"
import cx from "clsx"

import CookiesConsent from "src/components/cookies-consent"
import { device } from "src/utils/capacitor"
import global from "src/styles/global"
import normalize from "src/styles/normalize"

const Layout = props => {
  const { children, cookiesConsent, className } = props
  return (
    <div className={cx(normalize, global, className)}>
      {children}
      {!device.isNative() && <CookiesConsent text={cookiesConsent} />}
    </div>
  )
}

export default Layout
