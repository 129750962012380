// inspired by Tailwind
// https://github.com/tailwindlabs/tailwindcss/blob/0221f2f003875fde44395cecc12cca61b5168231/stubs/defaultConfig.stub.js

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

const mediaEntries = Object.entries(screens).map(([key, width]) => [
  key,
  {
    up: `@media (min-width: ${width}px)`,
    down: `@media (max-width: ${width - 0.02}px)`,
  },
])

const media = mediaEntries.reduce(
  (acc, [k, v]) => ({
    ...acc,
    [k]: v,
  }),
  {}
)

// prettier-ignore
const spacing = [
  /*0*/ "0",        //   0px  |
  /*1*/ "0.125rem", //   2px  |-
  /*2*/ "0.25rem",  //   4px  |--
  /*3*/ "0.5rem",   //   8px  |----
  /*4*/ "0.75rem",  //  10px  |-----
  /*5*/ "1rem",     //  16px  |--------
  /*6*/ "1.25rem",  //  20px  |----------
  /*7*/ "1.5rem",   //  24px  |------------
  /*8*/ "2rem",     //  32px  |----------------
  /*9*/ "2.5rem",   //  40px  |--------------------
  /*10*/ "3rem",    //  48px  |------------------------
  /*11*/ "4rem",    //  64px  |--------------------------------
  /*12*/ "5rem",    //  80px  |----------------------------------------
  /*13*/ "6rem",    //  96px  |------------------------------------------------
  /*14*/ "8rem",    // 128px  |----------------------------------------------------------------
  /*15*/ "10rem",   // 160px  |--------------------------------------------------------------------------------
  /*16*/ "12rem",   // 192px  |------------------------------------------------------------------------------------------------
  /*17*/ "14rem",   // 224px  |----------------------------------------------------------------------------------------------------------------
  /*18*/ "16rem",   // 256px  |--------------------------------------------------------------------------------------------------------------------------------
]

const inset = size => `padding: ${size};`

const squish = size => `padding: ${size} calc(2 * ${size});`

const stretch = size => `padding: calc(2 * ${size}) ${size};`

const stack = size => `
  margin-bottom: ${size};
  &:last-child {
    margin-bottom: inherit;
  }
`

const inline = size => `
  margin-right: ${size};
  &:last-child {
    margin-right: inherit;
  }
`

const colors = {
  primary: "#ed0121",
  black: "#000",
  white: "#fff",
  gray: {
    100: "#f7fafc",
    200: "#edf2f7",
    300: "#e2e8f0",
    400: "#cbd5e0",
    500: "#a0aec0",
    600: "#718096",
    700: "#4a5568",
    800: "#2d3748",
    900: "#1a202c",
  },
}

const opacity = {
  0: "0",
  25: "0.25",
  50: "0.5",
  75: "0.75",
  100: "1",
}

export {
  screens,
  media,
  spacing,
  colors,
  opacity,
  inset,
  squish,
  stretch,
  stack,
  inline,
}
