import React, { useEffect, useState } from "react"
import { css } from "linaria"

import Container from "src/components/container"

import { colors, spacing, squish } from "src/styles/members"

const cookiesConsentStyle = css`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  background-color: rgba(33, 45, 57, 0.8);
  color: ${colors.white};
  padding-top: ${spacing[2]};
  padding-bottom: ${spacing[2]};
  .text {
    display: inline-block;
  }
  .details {
    color: ${colors.primary};
    margin-left: ${spacing[3]};
    display: inline-block;
  }
  .accept {
    background-color: ${colors.primary};
    border: none;
    color: ${colors.white};
    ${squish(spacing[2])};
    margin-left: ${spacing[3]};
    cursor: pointer;
  }
`

const enableTagManager = () => {
  const expiresStr = new Date(2147483647 * 1000).toUTCString()
  document.cookie = `gatsby-gdpr-google-tagmanager=true; expires=${expiresStr};`
}

const isTagManagerEnabled = () => {
  return /gatsby-gdpr-google-tagmanager=true/i.test(document.cookie)
}

const CookiesConsent = props => {
  const { text } = props
  const [isVisible, setVisible] = useState(false)
  useEffect(() => {
    if (!isTagManagerEnabled()) {
      setVisible(true)
    }
  }, [])
  const handleAccept = () => {
    enableTagManager()
    setVisible(false)
  }
  return (
    isVisible && (
      <div className={cookiesConsentStyle}>
        <Container>
          <span className="text">{text}</span>
          <a href="/docs/consent.pdf" target="_blank" className="details">
            Podrobnosti o použití cookies
          </a>{" "}
          <button onClick={handleAccept} className="accept">
            Rozumiem
          </button>
        </Container>
      </div>
    )
  )
}

export default CookiesConsent
