import React from "react"
import { css } from "linaria"
import cx from "clsx"

import { media, screens } from "src/styles/members"

const container = css`
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  ${media.sm.up} {
    max-width: ${screens.sm - 60}px;
  }
  ${media.md.up} {
    max-width: ${screens.md - 60}px;
  }
  ${media.lg.up} {
    max-width: ${screens.lg - 60}px;
  }
`

const Container = props => {
  const { className, children } = props
  return <div className={cx(container, className)}>{children}</div>
}

export default Container
